@import "../../styles/colors.module.scss";

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 1rem;

  .form {
    width: 25rem;
    max-width: 100%;
    margin: auto;
    padding-bottom: 5rem;

    .logo {
      display: block;
      width: 70%;
      margin: auto;
    }

    p {
      color: #cc3636;
    }

    .submit {
      width: 100%;
    }
  }
}
