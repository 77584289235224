@import "../../styles/colors.module.scss";
@import "../../styles/screenSizes.module.scss";

.container {
  width: 90%;
  max-width: $max-width;
  margin: 1rem auto;

  .form {
    max-width: 100%;
    width: 30rem;
  }
}
