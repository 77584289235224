@import "../../styles/colors.module.scss";

.button {
  background-color: $primary;
  border-color: $primary;
  min-width: 5rem;
  padding: 0.3rem 2rem;
  margin: 1rem 0 1rem auto;
  display: block;

  &:hover {
    background-color: $secondary;
    border-color: $secondary;
  }

  &:active,
  &:focus {
    background-color: $primary;
    border-color: $primary;
  }

  &:disabled {
    background-color: grey;
    border-color: grey;
  }
}
