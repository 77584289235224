@import "../../styles/colors.module.scss";

.input {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  label {
    align-self: flex-start;

    &.required::after {
      content: "*";
      color: red;
    }
  }

  textarea {
    min-height: 8rem;
  }

  .search {
    width: 100%;
    margin-top: 1rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    max-height: 10rem;
    overflow: auto;

    .item {
      padding: 5px;

      &:nth-child(odd) {
        background-color: #f5f5f5;
      }
      &:hover {
        cursor: pointer;
        background-color: #efefef;
      }
      &.active {
        background-color: $secondary;
        color: white;
      }
    }
  }
}
