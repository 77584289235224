@import "../../../styles/colors.module.scss";
@import "../../../styles/screenSizes.module.scss";

.container {
  width: 90%;
  max-width: $max-width;
  margin: 1rem auto;

  .form {
    max-width: 100%;
    width: 30rem;

    h3 {
      margin-top: 2rem;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      button {
        margin: 1rem 0 0;
      }
    }

    .submit {
      margin-top: 3rem;
      padding: 0.3rem 4rem;
    }
  }
}

.inputWithRemove {
  display: flex;
  width: 100%;

  img {
    height: 2.5rem;
    width: 1.5rem;
    padding-top: 1rem;
    margin: auto 0.5rem auto 0;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  div {
    width: 100%;
  }
}
