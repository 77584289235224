@import "../../styles/colors.module.scss";
@import "../../styles/screenSizes.module.scss";

.nav {
  background-color: $primary;
  box-shadow: 0 0 0 0.25rem $bg-shadow;

  .navContainer {
    padding: 0.7rem 0;
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    max-width: $max-width;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    min-height: 2rem;

    .toggle-btn {
      display: none;
    }

    .title h1 {
      color: white;
      cursor: pointer;
      font-weight: 400;
      font-size: 1.7rem;
    }

    .navbar-collapse {
      flex: 1;

      ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;

        li {
          list-style-type: none;
          margin: 0.5rem 0 0.5rem 1rem;

          p {
            color: #ccc;
          }

          p:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  .nav {
    .navContainer {
      height: auto;
      background-color: transparent;

      .toggle-btn {
        display: block;
        background-color: transparent;
        border: none;
      }

      .navbar-collapse {
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 20px;
        left: 20px;
        z-index: 1;
        background-color: $bg2;
        border-radius: 3px;
        box-shadow: 5px 5px 5px $bg-shadow;

        ul {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;

          li {
            list-style-type: none;
            margin: 0;
            padding: 0.5rem 0;
          }
        }

        button {
          display: block;
          width: 25%;
          min-width: fit-content;
          margin: 0 auto 0.5rem;
        }
      }
    }
  }
}
