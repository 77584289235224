@import "../../styles/colors.module.scss";
@import "../../styles/screenSizes.module.scss";

.container {
  width: 90%;
  max-width: $max-width;
  margin: 1rem auto;

  .form {
    max-width: 100%;
    width: 30rem;

    .buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      button {
        margin: 1rem 0;

        &.clear {
          background-color: white;
          border-color: red;
          color: red;
        }
      }
    }
  }
}
