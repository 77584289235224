@import "../../styles/colors.module.scss";
@import "../../styles/screenSizes.module.scss";

.container {
  width: 90%;
  max-width: $max-width;
  margin: 1rem auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    button {
      margin: 0;
    }
  }

  .row {
    td {
      vertical-align: middle;

      .button {
        margin: 0;
      }
    }
  }
}
