@function getShadow($color) {
  @return rgba($color, 0.15);
}

$primary: #6f3e2d;
$secondary: #be7339;

$bg2: #2f2f37;
$bg: #fff;

$bg2-shadow: getShadow($bg2);
$bg-shadow: getShadow($bg);
